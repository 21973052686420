/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import { ThemeProvider } from "styled-components";
import theme from "./src/theme/theme";
import { GlobalContextProvider } from "./src/context/GlobalContext";

require("typeface-montserrat");

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalContextProvider>{element}</GlobalContextProvider>
  </ThemeProvider>
);
