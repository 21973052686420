import React from "react";

const defaultState = {
  color: "light",
  setColor: () => {},
};

const GlobalContext = React.createContext(defaultState);

const GlobalContextProvider = ({ children }) => {
  const [headerThemeColor, setHeaderThemeColor] = React.useState("light");

  return (
    <GlobalContext.Provider
      value={{
        headerColor: headerThemeColor,
        setHeaderColor: setHeaderThemeColor,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalContextProvider };
