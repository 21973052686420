import colors from "./colors";
import { fontFamilies, fontSizes } from "./textStyles";

const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors: colors,
  fonts: fontFamilies,
  fontSizes: fontSizes,
  breakpoints: ["599px", "768px", "920px", "1140px"],
};

export default theme;
