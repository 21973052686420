const colors = {
  primary: "var(--color-primary)",
  primaryPalette: {
    50: "var(--color-primary-50)",
    100: "var(--color-primary-100)",
    200: "var(--color-primary-200)",
    300: "var(--color-primary-300)",
    400: "var(--color-primary-400)",
    500: "var(--color-primary-500)",
    600: "var(--color-primary-600)",
    700: "var(--color-primary-700)",
    800: "var(--color-primary-800)",
    900: "var(--color-primary-900)",
    A100: "var(--color-primary-a100)",
    A200: "var(--color-primary-a200)",
    A400: "var(--color-primary-a400)",
    A700: "var(--color-primary-a700)",
  },
  success: "",
  successPalette: {},
  warning: "var(--color-warning)",
  light: "var(--color-light)",
  dark: "var(--color-dark)",
  darkPalette: {
    300: "var(--color-dark-300)",
    400: "var(--color-dark-400)",
    500: "var(--color-dark-500)",
  },
};

export default colors;
